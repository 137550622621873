import React from 'react';
import { Container, Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isNull from 'lodash/isNull';

import { styleConfig } from '../../config/config';

import Section from './Common/Section';
import Title from './Common/Title';

const ContentModuleIntro: React.SFC<ContentModuleIntroProps> = (props) => {
  const { headline, subtitle, content } = props;

  const useStyles = makeStyles(() => ({
    title: {
      display: 'block',
      marginBottom: '4rem',
    },
    body: {
      textAlign: 'center',
    },
  }));

  const classes = useStyles();

  return (
    <Section bg={styleConfig.color.bg.darkgrey}>
      <Container maxWidth="xl">
        <Grid container justifyContent="center">
          {!isNull(headline) && !isNull(subtitle) && headline && (
            <Grid item sm={12} md={10}>
              <Box className={classes.title}>
                <Title
                  alignment="center"
                  isSubtitle
                  subtitleText={subtitle}
                  subtitleType="subtitle1"
                  titleCaps
                  titleColor={styleConfig.color.text.white}
                  titleHeavy
                  titleSize="h2"
                  titleText={headline}
                />
              </Box>
            </Grid>
          )}

          <Grid item sm={12} md={10} lg={8} xl={6}>
            <Typography
              className={classes.body}
              component="div"
              key="cm-intro-content"
              variant="body1"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

interface ContentModuleIntroProps {
  headline: string;
  subtitle: string;
  content: string;
}

export default ContentModuleIntro;
